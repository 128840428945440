<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="800px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      :rules="rules"
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item">
        <template #label>
          <span class="label">門市</span>
        </template>
        <el-cascader
          v-model="ruleForm.shop_id"
          :options="getStoreList"
          :show-all-levels="false"
          placeholder="請選擇或輸入門市名稱、代碼"
          style="width: 100%"
          clearable
          filterable
          :props="{ emitPath: false }"
        />
      </el-form-item>
      <el-form-item
        class="form-item"
        prop="date"
        :rules="[
          {
            required: true,
            message: '請選擇交易日期',
            trigger: 'blur',
          },
        ]"
      >
        <template #label>
          <span class="label">交易日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          unlink-panels
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          :default-value="timeDefaultShow"
        />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">來源</span>
        </template>
        <el-select v-model="ruleForm.source_platform" multiple placeholder="請選擇來源" style="width: 100%">
          <el-option
            v-for="item in getOptions?.source_platform"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">類型</span>
        </template>
        <el-select v-model="ruleForm.take_away_method" multiple placeholder="請選擇類型" style="width: 100%">
          <el-option
            v-for="item in getOptions?.take_away_method"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">訂單狀態</span>
        </template>
        <el-select v-model="ruleForm.status" multiple placeholder="請選擇狀態" style="width: 100%">
          <el-option v-for="item in getOptions?.status" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item :prop="attestingType" class="form-item">
        <template #label>
          <span class="label">單據</span>
          <el-radio-group v-model="attestingType" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio label="invoice_no">發票號碼</el-radio>
            <el-radio label="serial_number">交易序號</el-radio>
          </el-radio-group>
        </template>
        <el-input
          v-model="ruleForm[attestingType]"
          :placeholder="`請輸入${attestingType === 'invoice_no' ? '發票號碼' : '交易序號'}`"
        />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">發票狀態</span>
        </template>
        <el-select v-model="ruleForm.invoice_status" clearable placeholder="請選擇發票狀態" style="width: 100%">
          <el-option
            v-for="item in Object.keys(mapInvoiceStatus)"
            :key="item"
            :label="mapInvoiceStatus[item].label"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, reactive, ref, watch } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
import { mapInvoiceStatus } from '@/utils/define';
export default {
  name: 'search-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    const getSearchCondition = useGetters('order', ['getSearchCondition']);
    if (dialogVisible) {
      ruleForm.value = JSON.parse(JSON.stringify(getSearchCondition.value));
    }
    const attestingType = ref('invoice_no');
    if (ruleForm.value.serial_number) attestingType.value = 'serial_number';
    watch(attestingType, (type, prevType) => {
      ruleForm.value[prevType] = '';
    });

    //各式選項-----------------------------------------------------
    const getStoreList = useGetters('common', ['getStoreList']);
    const getOptions = useGetters('order', ['getOptions']);

    //日期相關-----------------------------------------------------
    //日期快捷
    const shortcuts = [
      {
        text: '當天',
        value: () => {
          const end = new Date();
          const start = new Date();
          return [start, end];
        },
      },
      {
        text: '當周',
        value: () => {
          const end = new Date();
          const start = new Date(end.getTime() - 3600 * 1000 * 24 * (end.getDay() === 0 ? 6 : end.getDay() - 1));
          return [start, end];
        },
      },
      {
        text: '當月',
        value: () => {
          const end = new Date();
          const start = new Date(`${end.toLocaleDateString().slice(0, 7)}/01`);
          return [start, end];
        },
      },
    ];
    //預設顯示日期
    const timeDefaultShow = new Date();
    timeDefaultShow.setMonth(new Date().getMonth() - 1);
    //禁用日期
    const disabledDate = (time) => {
      const today = new Date();
      if (time.getTime() > today.getTime()) return true;
    };

    //驗證與檢查-----------------------------------------------------------------
    const validateInvoice = (rule, value, callback) => {
      const regex = /^[a-zA-Z]{2}[-]?[0-9]{8}$/;
      if (value && !regex.test(value)) return callback(new Error('請輸入正確的發票號碼，2碼英文+8碼數字'));
      else callback();
    };
    const validateSerial = (rule, value, callback) => {
      const regex = /^[0-9]{11}$/;
      if (value && !regex.test(value)) return callback(new Error('請輸入正確的訂單序號，共11碼數字'));
      else callback();
    };
    const rules = reactive({
      invoice_no: [{ validator: validateInvoice, trigger: 'blur' }],
      serial_number: [{ validator: validateSerial, trigger: 'blur' }],
    });
    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getSearchCondition.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getSearchCondition.value));
      }
      //未選擇時間
      if (!ruleForm.value.date || ruleForm.value.date.length === 0) isValid = false;
      return isValid;
    };

    //送出
    const router = useRouter();
    const doSearchOrderList = useActions('order', ['doSearchOrderList']);
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log(ruleForm.value);
        await doSearchOrderList({ start: 0, search: ruleForm.value, type: 'search' });
        router.push('/order/list?page=1');
        close();
      } catch (e) {
        e;
      }
    };
    return {
      mapInvoiceStatus,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      attestingType,
      getStoreList,
      getOptions,
      shortcuts,
      timeDefaultShow,
      disabledDate,
      rules,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
